.license_table {

  form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .form-stack {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
    }
  }
}
