















.components__generic-accordion {
  .accordion-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .accordion-chevron {
      font-size: 20px;
    }
  }
}
