




















































































@import "~stylesheets/frontend/variables/colors";

.components__tabbed-content {
  .tabs {
    display: flex;

    .tab {
      padding: 8px 20px;
      margin-right: 16px;
      font-size: 16px;
      border-radius: 2px;
      cursor: pointer;
    }

    margin-bottom: 28px;
  }
}
