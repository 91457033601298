.list-entry-flex-box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 15px;
    border: solid 1px grey;
    align-items: center;
    padding: 5px;
}

.my_tile_progress {
    padding: 10px 16px !important;

    .boxes {
        margin: 0 !important;
    }
}

.marker {
    flex: 0 0 1.5em;
    padding-left: 10px;
}

.spacer {
    flex-grow: 1;
}

.progress-box {
    min-width: 200px;
}
.tabbed {
    padding-left: 45px;
    border-left: solid 1px lightgrey;
}
