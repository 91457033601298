






































.components__rec-text-field {
  textarea {
    width: 100%;
    resize: vertical;
  }

  .text-field-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
