.apt_table-show {

  .apt-table-rendering {
     // General grid definition
     @import "~include-media/dist/include-media";

     $breakpoints: (
       "desktop": 1600px,
       "tablet": 1160px,
       "phone": 860px,
     );

    display: none;

    &.apt-table-rendering-desktop {
      @include media(">=desktop") {
        display: initial;
      }
    }

    &.apt-table-rendering-tablet {
      @include media("<desktop", ">=tablet") {
        display: initial;
      }
    }

    &.apt-table-rendering-phone {
      @include media("<tablet", ">=phone") {
        display: initial;
      }
    }

    &.apt-table-rendering-small {
      @include media("<phone") {
        display: initial;
      }

      table {
        td, th {
          font-size: 14px;
        }
      }
    }

    table {
      margin-bottom: 20px;
    }
  }
}
