





































































.href-target--disabled {
  cursor: not-allowed;
}

.href-target--selected {
}

.selection-checkbox {
  display: none;
}
