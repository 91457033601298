
































































































@use "~stylesheets/frontend/variables/colors";

.components__exam-sheet {
  .sheet {
    .card {
      background-color: colors.$color-white;
      box-shadow: 0 0 15px 0 rgba(3, 3, 3, 0.1);
      color: colors.$color-default;
      padding: (66px - 18px)/2;
      margin-bottom: 23px;

      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }

      &.filter {
      }

      &.score-items {
        .score-item {
        }

        .button-bar {
          height: 50px;
          .button-next {
            margin-top: 15px;
            float: right;

            .button-next-a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
