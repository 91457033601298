

























@import "~include-media/dist/include-media";

$breakpoints: (
  "tablet": 1000px,
);

.columns {
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
  }
}

.scores {
  display: flex;
}

