@use "~stylesheets/frontend/variables/colors";

.tile {
  width: 270px;

  $_box_shadow_unhovered: 0 0 15px 0 rgba(3, 3, 3, 0.1);
  box-shadow: $_box_shadow_unhovered;

  background-color: colors.$color-white;
}

.tile__compartment {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}

.tile__category {
  $_local_padding_tb: 6px;
  padding-top: $_local_padding_tb;
  padding-bottom: $_local_padding_tb;

  font-size: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tile__category__version-code {
  font-size: 12px;
}

.tile__heading {
  min-height: 90px;
  margin-bottom: 15px;
}

.tile__title {
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 18px;

  display: flex; // for "more-info" icon
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.tile__title__text {
  // postcss-autoprefixer not working
  // see: https://github.com/postcss/autoprefixer/issues/1322
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  // use ellipsis
  overflow: hidden;
  text-overflow: ellipsis;
}

.tile__more-info {
  font-size: 25px;
}

.tile__subtitle {
  font-size: 14px;
}

.tile__progress,
.tile__hint {
  font-size: 14px;
  padding-top: 19px;
  padding-bottom: 19px;
}

.tile__hint {
  background-color: colors.$color-red-light;

  a {
    text-decoration: underline;
  }
}

.tile__infos,
.tile__actions {
  height: 67px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tile__action,
.tile__info {
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.tile__action {
  outline: none;
  background-color: transparent;

  cursor: pointer;
  // border-radius: 4px;
  padding: 6px;
  font-size: 25px;
}

.tile__info {
  font-size: 34px;
  padding: 3px;

  color: colors.$color-black-inactive;
}

.tile__info--placeholder {
  width: 34px;
  height: 34px;
}

.tile__action--placeholder {
  width: 39px;
}

.tile__target {
  padding-top: 7px;
  padding-bottom: 7px;

  font-size: 14px;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tile--disabled {
  .tile__main {
    $_disable_color: transparentize(black, 0.9);
    position: relative;

    .tile__disabled-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $_disable_color;
      z-index: 10;
    }
  }
}


