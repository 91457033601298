















.components__rec-check-box {
  label {
    > input {
      vertical-align: middle;
      margin-right: 10px;
    }
    > span {
      vertical-align: middle;
    }
  }
}
