





























@import "~stylesheets/frontend/variables/colors";

.components__competency-accordion {
  .sub-competency {
    display: flex;
    padding: 5px;

    &.highlight {
      background-color: $color-green-hueish;
    }

    .sub-competency__abbreviation {
      margin-right: 10px;
    }
  }
}
