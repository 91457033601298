






.components__rec-rich-text {
  .rich-text-content {
    &,
    * {
      font-size: 16px;
    }
  }
}
