












.components__cnt-image {
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
}
