// Used in Vue-components cnt-table_privacy (both authortool and frontend)
// to style these components in scoped variant

table {
  width: 100%;

  border-collapse: collapse;
  border-spacing: 0;

  td {
    border: 1px solid black;
    padding: 10px;
  }
}

.privacy-table__descriptive {
  width: 200px;
  vertical-align: middle;
}
.privacy-table__descriptive-wrapper {
  display: flex;
  align-items: center;

  font-size: 25px;
  img {
    margin-right: 10px;
    max-width: 50px;
  }
}
