









































.components__rec-history-causes {
  p,
  ul,
  li {
    font-size: 16px;
  }
}
