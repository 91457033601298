























.components__rec-diagnosis-selector {
  p,
  ul,
  li {
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding-left: 20px;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
