





































.components__info-item {
  p,
  ul,
  ol,
  li {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
}
