@import "~stylesheets/frontend/variables/colors";

.operators {
  text-align: center;
  padding-top: 68px;
  padding-bottom: 84px;
}

:where(.operators) {
  background-color: $color-green-hueish;
}

.operators__title {
  font-weight: 300;
  font-size: 44px;
  margin-top: 0;
  margin-bottom: 5px;
}

.operators__subtitle {
  font-size: 18px;
  line-height: 26px;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 49px;
}

.operators__logos {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media("<tablet2") {
    flex-direction: column;
  }

  .operators__logo {
    height: auto;

    &.operators__logo--dgaap img {
      width: 183px;
      @include media(">=tablet2") {
        margin-right: 37px;
      }
    }
    &.operators__logo--bvkj img {
      width: 120px;
      @include media(">=tablet2") {
        margin-right: 50px;
      }
    }
    &.operators__logo--bvkj-service img {
      width: 200px;
    }

    @include media("<tablet2") {
      margin-bottom: 37px;
    }

    &.operators__logo--dgaap,
    &.operators__logo--bvkj,
    &.operators__logo--bvkj-service {
      img {
        @include media("<phone2") {
          width: 30vw;
        }
      }
    }
  }
}
