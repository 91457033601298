
















.components__simple-datepicker {
  label {
    > div {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}
