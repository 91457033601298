













.components__logo-paedcompenda {
  width: 221px;

  img {
    width: 100%;
  }
}
