















































































@import "~stylesheets/frontend/variables/colors";

.components__simple-textfield {
  .label-container {
    margin-bottom: 3px;
  }

  label {
    width: 100%;

    color: $color-default;
    font-size: 18px;

    &.very-small {
      font-size: 14px;
    }
  }

  .input-container {
    position: relative;

    i {
      position: absolute;
      right: 14px;
      top: 20px;
      font-size: 20px;
      color: $color-black-placeholder;
    }
  }

  input {
    width: 100%;
    height: 60px;
    border: 1px solid $color-black-border;
    border-radius: 2px;
    background-color: $color-white;
    box-shadow: none;
    padding-left: 14px;

    &.small {
      height: 30px;
    }

    &.very-small {
      height: 25px;
      width: initial;
    }

    &::placeholder {
      color: $color-black-placeholder;
      font-size: 18px;
    }

    &:focus {
      border: 1px solid $color-green;
      box-shadow: none;
      box-shadow: 0 0 10px 0 transparentize($color-green, 0.7);
      outline: none;
    }
  }
}
