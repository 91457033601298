@import "~noty/lib/noty";
@import "../variables/colors";

// Overwrite these two colors to use them
$color-background-accent: $color-green;
$color-text-inverted: $color-white;

.noty_theme__mint.noty_type__success {
  background-color: $color-background-accent;
  font-weight: lighter;
}

.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  background-color: $color-text-inverted;
  opacity: 0.75;
}
