


















































.components__cnt-slide_show {
  @import "~stylesheets/frontend/variables/colors";

  @media print {
    // hide content in print screen
    display: none;
  }

  &:focus {
    outline: none;
  }

  background-color: $color-black-very-light;

  img {
    max-width: 100%;
    max-height: 800px;

    background-color: white;
  }

  .slide__navigation {
    background-color: $color-black-placeholder;
    color: white;
    padding: 10px 0;

    display: flex;
    align-items: center;

    > div {
      flex-basis: 30%;
      flex-grow: 1;
      flex-shrink: 0;
    }

    .slide__navigation_section {
      display: flex;
      align-items: center;
    }
    .slide__navigation_section--center {
      justify-content: center;
    }
    .slide__navigation_section--right {
      justify-content: flex-end;
    }

    a,
    i {
      margin: 0 10px;
      color: white;
      cursor: pointer;
      user-select: none;
    }

    input {
      width: 60px;
      border: none;
      background-color: $color-black-very-light;
      color: $color-green;
      margin-right: 2px;

      text-align: left;
      &,
      &:focus {
        outline: none;
        border-radius: none;
      }
    }

    // hide arrows on number field
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .slide__image-container {
    position: relative;
    text-align: center;
  }

  .slide__overlay {
    // remove drag and drop
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:fullscreen {
    img {
      max-height: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .not-authorized {
    display: flex;
    padding: 40px 30px;
    align-items: center;

    .frontend-icon {
      color: $color-green;
      margin-right: 15px;
      font-size: 30px;
    }
  }
}
