.kaminari-pagination {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-top: 1px solid black;
  border-bottom: 1px solid black;

  nav {
    display: flex;
    justify-content: space-between;
  }
}
