@import "~stylesheets/frontend/variables/components/top-bar";
@import "~stylesheets/frontend/variables/colors";
@import "~stylesheets/frontend/variables/components/content-topbar";

.components__content-topbar {
  background-color: $color-white_background;

  .content-container {
    // taking top-bar into account
    padding-top: $top-bar_height;
    min-height: calc(100vh - #{$top-bar_height});

    &.padded {
      $_padding-tb: 20px;
      $_padding-lr: 20px;

      padding-top: $top-bar_height + $_padding-tb;
      padding-bottom: $_padding-tb;
      padding-left: $_padding-lr;
      padding-right: $_padding-lr;
    }

    &.maxed {
      max-width: $content-page_max-width + $content-topbar_padding-left_mobile +
        $content-topbar_padding-right_mobile;
      margin-left: auto;
      margin-right: auto;

      padding-left: $content-topbar_padding-left_mobile;
      padding-right: $content-topbar_padding-right_mobile;
    }

    .breadcrumbs-container {
      padding-left: 50px;
      padding-right: 50px;
      margin-top: 30px;

      @include media("<=500px") {
        padding-left: 10px;
        padding-right: 10px;
      }

      a {
        color: $color-green;
      }

      &.breadcrumbs-container--small {
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }
}

@import "~stylesheets/frontend/variables/components/side-bar";
@import "~stylesheets/frontend/variables/routes/content-page";

@import "~include-media/dist/include-media";

$breakpoints: (
  "sidebar": $side-bar_breakpoint,
);

.components__content-topbar-sidebar {
  .content-topbar-horizontal {
    min-height: calc(100vh - #{$top-bar_height});
    display: flex;

    .sidebar {
      @include media("<sidebar") {
        display: none;
      }
    }

    .content-container_right {

      box-shadow: inset 15px 0px 15px -15px rgba(136, 136, 136, 0.3);
      @include media("<sidebar") {
        width: 100%;
      }
      @include media(">=sidebar") {
        width: calc(100% - #{$side-bar_width});
      }

      .menu-button {
        @include media(">=sidebar") {
          display: none;
        }
      }

      &.padded {
        padding-left: $content-page_padding-left;
        padding-right: $content-page_padding-right;
        padding-top: $content-page_padding-top;
        padding-bottom: $content-page_padding-bottom;

        max-width: calc(
          100% - #{$content-page_padding-left +
            $content-page_padding-right}
        );

        @include media("<sidebar") {
          padding-left: $content-page_padding-left_mobile;
          padding-right: $content-page_padding-right_mobile;
          padding-top: $content-page_padding-top_mobile;
          padding-bottom: $content-page_padding-bottom_mobile;

          max-width: calc(
            100% - #{$content-page_padding-left_mobile +
              $content-page_padding-right_mobile}
          );
        }
      }
    }
  }
}

.accented-background {
  background-color: $color-green;
}
