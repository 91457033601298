























































































































































































































































































































































































































































































































































































































































































.components__cnt-table_milestones {
  @import "~stylesheets/frontend/specific/content-widgets_tables";
  table {
    max-width: 750px;
  }
}
