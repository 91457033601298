































































@import "~stylesheets/frontend/variables/colors";

.components__print-button {
  .button-container {
    display: flex;
    margin-top: 15px;

    .button-a {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .button-a--small {
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 2px;

      i {
        font-size: 16px;
      }
    }

    .button-content {
      display: flex;
      align-items: center;
    }

    .button-label {
      margin-left: 5px;
      line-height: 1;
    }
  }
}

