


















@import "~stylesheets/frontend/variables/colors";

.components__progress-tile-bar {
  .boxes {
    margin-top: 9px;

    display: flex;
    .box {
      height: 22px;
      text-align: center;
      color: $color-default;
      font-size: 14px;
      line-height: 22px;

      cursor: default;

      &.inverted {
        color: $color-white;
      }
    }
  }
}
