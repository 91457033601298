



















.components__rec-component-collection {
  .single-component {
    &.distanced {
      margin-bottom: 20px;
    }
  }
}
