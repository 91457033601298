
















































































@import "~stylesheets/frontend/variables/components/side-bar";
@import "~stylesheets/frontend/variables/components/top-bar";
@import "~stylesheets/frontend/variables/colors";

@import "~include-media/dist/include-media";

$breakpoints: (
  "sidebar": $side-bar_breakpoint,
);

.components__side-bar {
  &#sidebar-navigation {
    width: $side-bar_width;
    min-width: $side-bar_width;
    min-height: calc(100vh - #{$top-bar_height});
    box-sizing: border-box;

    &:target {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 99;

      width: 100%;
      height: 100%;

      .menu-button-close {
        display: block;
      }
    }
  }

  $_sidebar_padding-left: 21px;

  .menu-button-close {
    display: none;
    padding-left: $_sidebar_padding-left;
  }

  background-color: $color-white;
  padding-top: 17px;

  .title {
    margin-left: $_sidebar_padding-left;
    margin-top: 12px;
    margin-bottom: 21px;
    font-size: 18px;
    color: $color-green;

    display: flex;
    align-items: center;

    &.extra-top-space {
      margin-top: 52px;
    }
  }

  .title__label {
    display: inline-block;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space:nowrap;

    @include media(">=sidebar") {
      width: 212px;
    }
  }

  .title--accordion {
    user-select: none;
    cursor: pointer;
  }

  .entries {
    div {
      color: $color-default;
    }
  }

  .entry,
  .sub-entry {
    $_entry-border: 1px solid $color-black-very-light;
    padding-left: $_sidebar_padding-left;
    border-top: $_entry-border;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;

    &:last-of-type {
      border-bottom: $_entry-border;
    }

    &.active {
      background-color: $color-green-hueish;
    }

    &.sub-entry {
      padding-left: $_sidebar_padding-left + 15px;
      color: $color-white_secondary-text;
    }
  }
}
