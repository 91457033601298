@import "~stylesheets/frontend/variables/colors";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 450px,
);

.login {
  .login-panel {
    max-width: 432px;
    margin-left: auto;
    margin-right: auto;
  }

  .login-button {
    box-sizing: border-box;
    width: 100%;

    height: 57px;

    margin-bottom: 15px;
  }

  .sub-buttons {
    display: flex;
    justify-content: space-between;

    color: $color-white_secondary-text;

    margin-bottom: 30px;
  }

  .title {
    text-align: center;
    font-weight: 300;
    font-size: 44px;
    color: $color-green;
    margin-bottom: 40px;

    @include media("<phone") {
      font-size: 15vw;
    }
  }

  .stay-logged-in {
  }

  .textfield {
    &.email {
      margin-bottom: 20px;
    }

    &.password {
      margin-bottom: 60px;
    }
  }
}
