






































@import "~stylesheets/frontend/variables/colors";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "phone2": 500px,
  "tablet": 600px,
  "tablet2": 720px,
  "desktop": 1078px,
  "large": 1260px,
);

$height_breakpoint: 800px;
$reduced_height_padding: 35px;

// specificality 0 to not overwrite / interfere theming
:where(.jump-tile) {
  border: 1px solid $color-green;
  background-color: $color-green-hueish;
  box-shadow: 0 0 10px 0 transparentize($color-green, 0.7);
}
:where(.jump-tile-icon) {
  color: $color-green;
}

.jump-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @include media("<phone2") {
    justify-content: space-around;
  }
  margin-bottom: 99px;

  @include media("height<=#{$height_breakpoint}") {
    margin-bottom: $reduced_height_padding;
  }

  .jump-tile {
    position: relative;

    $_jump-tile_padding: 15px;
    width: 180px;
    min-height: 220px;
    padding: $_jump-tile_padding;

    border-radius: 5px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;

    .jump-tile-icon {
      font-size: 45px;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 10px;
    }
    .jump-tile-description {
      &,
      p {
        font-size: 16px;
        color: $color-default;
        text-align: center;
        line-height: 23px;
      }
    }
    .jump-tile-button-spacer {
      visibility: hidden;
      padding: 20px 40px;
    }
    .jump-tile-button {
      position: absolute;
      bottom: $_jump-tile_padding;
      left: $_jump-tile_padding;
      right: $_jump-tile_padding;
      font-size: 16px;
    }

    &::after {
      content: "";
      flex: auto;
    }
  }
}
