





























.components__rec-score-category {
  position: relative;
  &.score-category--with-legend {
    margin-top: 30px;
  }

  .score-category-legend {
    position: absolute;
    top: -25px;
    right: 0;
    text-align: right;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
