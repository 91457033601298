

.search__field {
  input {
    border-radius: 0;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 5px;

    background-color: transparent;

    &::placeholder {
      font-weight: lighter;
      font-style: italic;
    }

    &:focus {
      border-color: $color-green;
    }
  }

  form {
    margin: 0;
    padding: 0;
  }

  display: flex;
  align-items: center;


}

.search__field--inverted {
  input {
    color: $color-inverted;

    &::placeholder {
      color: $color-inverted;
    }
  }
}

.search__field_container {
  display: flex;
  align-items: center;
}

.search__results {
  > div {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.search__tile {
  // border: 1px solid $color-green-light;

  $_padding_lr: 20px;

  background-color: white;

  .search__tile-type {
    font-weight: bold;
    font-size: 12px;
    // background-color: $color-green-light;
    padding: 5px 10px;

    text-transform: uppercase;

    display: inline-block;
  }

  .search__tile-title {
    padding: 10px $_padding_lr;
    font-size: 18px;
    // color: $color-green;

    // border-bottom: 1px solid $color-green-light;
  }

  .search__tile-description {
    &, & p {
      padding: 20px $_padding_lr;
      font-size: 16px;
      line-height: 18px;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .search__tile-actions {
    display: flex;
    flex-wrap: wrap;

    padding: 20px $_padding-lr;

    // border-top: 1px solid $color-green-light;
  }

  .search__tile-action {
    margin-right: 20px;

    &, a, a:visited {
      color: $color-green;
    }
  }
}


