

.generic-form {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  h3 {
    margin-top: 30px;
  }

  a {
    color: $color-green;
  }

  #error_explanation, .errors {
    li {
      color: red;
      font-size: 16px;
      line-height: 18px;
    }
  }

  form {
    .form-stack {
      label, b {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
      }
      margin-bottom: 10px;

      &.field_with_errors {
        // Fixing width of inputs
        display: flex;
        flex-direction: column;
        // --

        label {
          @import "../variables/colors";
          color: red;
        }
      }
    }

    input[type="text"], input[type="password"], input[type="email"], input[type="number"], select {
      width: 100%;
      height: 30px;
      padding-left: 10px;
      text-align: left;
    }

    .button {
      background-color: $color-green;
      padding: 10px 25px;
      color: $color-text-inverted;
      border: none;
      cursor: pointer;
      margin-top: 20px;

      &:hover {
        box-shadow: 0 0 10px 0 transparentize($color-green, 0.2);
      }
    }

    .form-row {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}

