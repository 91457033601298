























































@import "~stylesheets/frontend/variables/colors";
@import "~stylesheets/frontend/variables/font-families";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "phone2": 500px,
  "tablet": 600px,
  "tablet2": 720px,
  "desktop": 1078px,
  "large": 1260px,
);

$height_breakpoint: 800px;
$reduced_height_padding: 35px;

.components__cnt-list {
  margin-left: 60px;
  margin-right: 60px;
}

.list {
  display: grid;
  grid-gap: 30px 54px;
  grid-auto-flow: column;

  @include media("<desktop") {
    display: block;
  }
}

.list__entry {
  line-height: 28px;
  font-size: 18px;
  color: $color-black;

  display: flex;

  margin-bottom: 30px;

  > i {
    margin-right: 19px;
    font-size: 44px;
  }
}

.cnt-list__title {
  font-size: 45px;
  font-family: $font-family_project-title;
  font-weight: $font-weight_project-title;
  margin-bottom: 5px;
  margin-top: 0;

  @include media("<tablet") {
    font-size: 10vw;
    margin-bottom: 5px;
  }
}

.cnt-list__subtitle {
  font-size: 22px;
  margin-bottom: 66px;

  @include media("<desktop") {
    margin-bottom: 20px;
  }

  @include media("<tablet") {
    font-size: 5vw;
  }
}

// specificality 0 to not overwrite / interfere theming
:where(.cnt-list__subtitle) {
  color: $color-green;
}

:where(.list__entry__icon) {
  color: $color-green;
}
