$color-green: #19948A;
$color-green-blueish: #3F8BAC;
$color-green-hueish: #E8F5F4;
$color-green-hueish-accent: #CCE8E5;
$color-green-light: #9ED9D4;

$color-red-light: #F5C7CB;

$color-black: #333333;
$color-black-placeholder: #B4B4B4; //austauschen
$color-black-inactive: #BABABA; //austauschen
$color-black-border: #C0C0C0;
$color-black-very-light: #DEDEDE;
$color-black-vertical-line: #EFEFEF;
$color-black-input-border: #979797;

$color-grey-dark: #8E8E8E;

$color-white: #FFFFFF;
$color-white_project-title: #F5F5F5;
$color-white_background: #F7F7F7;
$color-white_secondary-text: #707070;

$color-default: $color-black;
$color-inverted: $color-white;
