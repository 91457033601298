












































































































































@import "~stylesheets/frontend/variables/colors";

.components__progress-area {
  .progress-single-area {
    &.inactive {
      display: none;
    }

    @media print {
      margin-top: 15px;
    }
  }
  .area-header {
    font-size: 18px;
    margin-bottom: 15px;

    .area-subtitle {
      margin-top: 6px;
      font-size: 16px;
      color: $color-default;
    }
  }
  .button-container {
    display: flex;
    margin-top: 15px;

    .button-prev {
    }

    .button-next {
      margin-left: auto;

      .button-container--inner {
        display: flex;
      }
    }

    .button-a {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .button-a--small {
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 2px;

      i {
        font-size: 16px;
      }
    }

    .print-button {
      margin-top: 15px;
    }

    .button-content {
      display: flex;
      align-items: center;
    }

    .button-label {
      margin-left: 5px;
      line-height: 1;
    }
  }

  .save-as-draft-explanation {
    margin-top: 20px;
  }
}
