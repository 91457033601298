$content-page_max-width: 1440px;

$content-page_padding-left: 50px;
$content-page_padding-right: $content-page_padding-left;
$content-page_padding-top: 43px;
$content-page_padding-bottom: $content-page_padding-top;

$content-page_padding-left_mobile: 10px;
$content-page_padding-right_mobile: $content-page_padding-left_mobile;

$content-page_padding-top_mobile: 10px;
$content-page_padding-bottom_mobile: $content-page_padding-top_mobile;
