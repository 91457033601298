// global available classes should have a "g--"-prefix


@import "./variables/colors";

.g--inverted-text {
  color: $color-inverted;
}

.g--accented-text {
  color: $color-green;
}

.text_page--without-color,
.text_page {
  @import "~include-media/dist/include-media";
  background-color: $color-white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 40px;
  padding-bottom: 50px;

  @include media("<=500px") {
    padding-left: 10px;
    padding-right: 10px;
  }

}

:where(.text_page) {
  a {
    color: $color-green;
  }
}


.g--broad_heading {
  box-shadow: 0 0 15px 0 rgba(3, 3, 3, 0.1);
  padding: (54px - 18px)/2;
  margin-bottom: 23px;

  // -- Removed due to theming
  // background-color: $color-green;
  // color: $color-white;
  border-radius: 2px;
  font-size: 18px;

  &.of_tiles {
    // removed variables
    margin-left: 25px;
    margin-right: 25px;
  }
}

input[type='submit'].submit_as_link {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  color: $color-green;
  margin-top: 5px;
  margin-bottom: 5px;
}

.link--accent {
  color: $color-green;
}
