.tile-dashboard {
}

.tile-dashboard__title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.tile-dashboard__tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  align-content: flex-start;

  > div, > a {
    margin: 25px;
  }
}
