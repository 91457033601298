




































































.components__rec-progress-bar {
  $_bubble-margin: 8px;

  position: relative;
  margin-bottom: 20px - $_bubble-margin;

  .bubbles {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .bubble {
      border-radius: 5px;
      font-size: 14px;

      height: 30px;
      min-width: 30px;
      max-width: 50px;
      text-align: center;
      line-height: 30px;
      margin-right: $_bubble-margin;
      margin-bottom: $_bubble-margin;

      display: flex;
      justify-content: center;

      cursor: pointer;

      $_animation_max_width: 700px;
      $_animation_duration: 0.75s;
      $_animation_function: linear;

      .bubble-text {
        max-width: 0;
        overflow: hidden;
        transition: all $_animation_duration $_animation_function;
      }

      .bubble-label {
        padding-left: 10px;
        padding-right: 10px;
      }

      transition: max-width $_animation_duration $_animation_function;
      &:hover,
      &.active {
        max-width: $_animation_max_width;
        transition: max-width $_animation_duration $_animation_function;
        text-align: left;

        .bubble-text {
          max-width: $_animation_max_width;
          transition: max-width $_animation_duration $_animation_function;
          margin-right: 15px;
        }
      }
    }
  }
}
