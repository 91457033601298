& {
  margin-top: 70px;
  margin-bottom: 70px;

  table {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    td {
      vertical-align: middle;
    }
  }

  .bracket-table_last {
    background-color: white;
    border-left: 1px solid black;
  }

  .table-caption {
    padding-top: 10px;
    text-align: center;
  }

  .table-centered {
    text-align: center;
  }
}
