@import '~vue-multiselect/dist/vue-multiselect.min';

.multiselect__spinner:before,
.multiselect__spinner:after,
.multiselect__tag,
.multiselect__option--highlight,
.multiselect__option--highlight:after
{
  background-color: $color-background-accent;
}
