































































































































































































.components__cnt-trial_version {
  @import "~stylesheets/frontend/variables/colors";

  .trial_version__not-logged-in {
    padding: 20px;
    background-color: $color-black-very-light;
  }

  .trial_version__option {
    display: block;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid $color-green;

    display: flex;
    align-items: center;

    select {
      margin-right: 20px;
    }
  }

  .button {
    background-color: $color-green;
    color: $color-inverted;

    padding: 10px 20px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .booking__error {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
  }

  .button--disabled {
    background-color: $color-black-inactive;
    cursor: not-allowed;
  }
}
