




















































  @use "sass:color";
  @use "~stylesheets/frontend/variables/colors";

.score-item-text {
  margin: 7px 0;
}

.score-item-text--extra-top-space {
  margin-top: 15px;
}

.score-item-text__textarea {
  width: 100%;
  resize: vertical;
}

.score-item-text__text-readonly {
  border: 1px solid black;
  background-color: white;
  padding: 10px;
}

.score-item-text__text-readonly--light {
  border-color: colors.$color-black-border;
  color: color.scale(colors.$color-black-border, $lightness: -40%);
}

.score-item-text__label {
  font-size: 12px;
  margin-bottom: 2px;

  padding-left: 2px;
}
