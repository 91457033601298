






















.components__rec-filter-bar {
  display: flex;

  .filter-entry {
    margin-right: 24px;

    input {
      margin-right: 8px;
      vertical-align: middle;
    }
    label {
      font-size: 16px;
    }
  }

  .filter-remove {
    margin-left: auto;
    margin-right: 36px - 18px;
    font-size: 14px;
    cursor: pointer;

    .frontend-icon {
      font-size: 25px;
      margin-right: 11px;
    }

    span {
      vertical-align: 2.5;
    }
  }
}
