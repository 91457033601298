




























































































@use "~stylesheets/frontend/variables/colors";

.components__score-item-bubble {
  > div {
    &.bubble-is-not-selected {
      background-color: colors.$color-white;
    }
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    user-select: none;

    &.light {
      background-color: colors.$color-white;
      $_light_color: colors.$color-black-very-light;
      $_light_color: colors.$color-black-border;
      border: 1px solid $_light_color;
      color: $_light_color;
      &.active {
        background-color: $_light_color;
      }
    }

    &.black {
      background-color: black;
      border: 1px solid white;
      color: white;
    }

    cursor: pointer;

    &.yes_no {
      font-size: 14px;
    }

    &.active {
      color: colors.$color-white;
      background-color: colors.$color-green;
    }

    &.hidden {
      visibility: hidden;
    }
  }
}
