.user-form {
  margin-left: 0;

  form {
    // General grid definition
    @import "~include-media/dist/include-media";

    $breakpoints: (
      "medium": 880px
    );

    $_grid_gutter: 10px;

    @mixin one-big-column($breakpoint) {
      @include media("#{$breakpoint}") {
        width: calc( 100% - #{1*$_grid_gutter} );
      }
    }

    .button {
      @include one-big-column("<medium");
    }

    .form-row {
      // gutter
      > div {
        margin-right: $_grid_gutter;
      }

      $_title_width: 20%;
      $_salutation_width: 50px;
      .title {
        width: $_title_width;

        @include one-big-column("<medium");
      }
      .salutation {
        width: $_salutation_width;

        @include one-big-column("<medium");
      }

      .first_name, .last_name {
        width: calc( (#{100% - 2*$_title_width} - #{4*$_grid_gutter} - #{$_salutation_width})/2 );

        @include one-big-column("<medium");
      }

      .lanr, .doctors_association_value {
        width: calc( (100% - #{2*$_grid_gutter}) / 2 );

        @include one-big-column("<medium");
      }

      $_address_width: 49%;
      $_zip_width: 15%;
      .address {
        width: $_address_width;

        @include one-big-column("<medium");
      }
      .zip {
        width: $_zip_width;

        @include one-big-column("<medium");
      }
      .city {
        width: calc( #{100% - $_address_width - $_zip_width} - #{3*$_grid_gutter} );

        @include one-big-column("<medium");
      }

    }
  }
}
