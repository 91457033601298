@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "tablet": 900px,
);

@import "~stylesheets/frontend/variables/colors";
@import "~stylesheets/frontend/variables/components/top-bar";
.front-page__top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $top-bar_height;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.shadowed {
    box-shadow: 0 1px 8px 0 rgba(9, 9, 9, 0.15);
  }

  background-color: $color-white;

  &.inverted {
    background-color: transparent;
  }

  color: $color-default;

  @include media(">=tablet") {
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .menu-button {
    @include media(">=tablet") {
      display: none;
    }

    margin-left: $top-bar_padding-left_mobile;
    color: $color-green;

    @include media("<phone") {
      font-size: 7vw;
    }
  }

  .inline {
    margin-left: 37px;
    margin-right: 150px;
    width: 260px;

    @include media("<tablet") {
      margin-left: auto;
      margin-right: 37px;
    }

    @include media("<phone") {
      width: 70vw;
      margin-right: 0;
      margin-top: 2vw;
    }
  }

  .nav-container {
    &#main-navigation {
      flex: 1;

      &:target {
        z-index: 999;
        display: block;
        position: fixed;

        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;

        background-color: $color-green-hueish;
        color: $color-default;

        .menu-button-close {
          display: block;
          margin-top: 20px;
        }

        .horizontal {
          display: block;

          > * {
            margin-bottom: 20px;
            margin-left: 10px;
          }

          .login-button {
            color: $color-default;
            border: 1px solid $color-default;
            margin-left: 10px;
          }
        }
      }

      .menu-button-close {
        display: none;
      }

      @include media("<tablet") {
        display: none;
      }

      .horizontal {
        display: flex;
        align-items: center;
        margin-right: 35px;
        font-size: 18px;

        .top-bar-account {
          margin-left: auto;


        }

        > * {
          margin-left: 60px;
        }
      }
    }
  }
}

.tb--submenu-entry {
  padding: 12px;

  &:hover {
    color: $color-green;
  }
}

.tb--submenu-account {
  padding-top: 22px;
  padding-bottom: 22px;
  padding-right: 30px;
  padding-left: 30px;
  .account-full-name {
    color: $color-green;
    font-size: 18px;
  }
  .account-roles {
    font-size: 14px;
    color: $color-default;
    margin-bottom: 14px;
  }
  .account-impersonation {
    font-size: 10px;
    color: $color-default;
    margin-top: 20px;
    text-align: right;
  }
  .account-ruled {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .account-link-to-account {
    margin-top: 4px;
  }
  .account-logout-button.components__simple-button {
    max-width: 119px;
    font-size: 14px;
    margin-left: auto;
    margin-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }
}

.top-bar-droplet.drop-element {
  margin-top: 35px;
  box-shadow: 0 0 15px 0 rgba(3,3,3,0.1);

  z-index: 9999;

  .tb--submenu-account {
    width: 295px;
  }
}
