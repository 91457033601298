.footer {
  padding-left: $front-page_padding-left;
  padding-right: $front-page_padding-right;
  max-width: $front-page_max-width;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  padding-top: 23px;
  padding-bottom: 46px;

  @include media("<phone2") {
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: $front-page_padding-left_mobile;
    padding-right: $front-page_padding-right_mobile;
  }

  .links {
    display: flex;
    @include media("<phone2") {
      flex-wrap: wrap;
    }

    .link {
      @include media(">=phone2") {
        margin-right: 52px;
      }
      @include media("<phone2") {
        margin-bottom: 1vw;
        margin-right: 2vw;
        font-size: 4vw;
      }
    }
  }
}
