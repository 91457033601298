













@import "~stylesheets/frontend/variables/colors";

.components__dropdown-menu {
  cursor: pointer;
  display: flex;
}

// style outside because tether moves it around
.dd-submenu {
  padding: 20px;
  background-color: $color-white;
}
