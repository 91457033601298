




























.components__rec-accordion {
  .accordion-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    &.expanded {
      padding-bottom: 20px;
    }

    .accordion-chevron {
      font-size: 30px;
      height: 15px;

      > i {
        line-height: 15px;
      }
    }

    .accordion-title {
      font-size: 18px;
    }
  }
}
