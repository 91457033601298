




































@import "~stylesheets/frontend/variables/colors";
.components__score-item-show-more-button {
  &.more {
    font-size: 14px;
    cursor: pointer;
    margin-top: 15px;

    .more-label {
      margin-right: 5px;
    }
  }
}
