





































































































































@use "~stylesheets/frontend/variables/colors";

.components__simple-button {
  &.theming-not-used {
    color: colors.$color-green;
    border: 1px solid colors.$color-green;

    &:hover,
    &.filled {
      background-color: colors.$color-green;
      color: colors.$color-inverted;
    }

    &.filled {
      &:hover {
        box-shadow: 0 0 10px 0 transparentize(colors.$color-green, 0.2);
      }
    }

    &.inverted {
      &:hover {
        color: colors.$color-green;
      }
    }
  }
}

.components__simple-button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 9px 30px;

  font-size: 18px;

  border-radius: 2px;

  cursor: pointer;
  user-select: none;

  &.center {
    text-align: center;
  }

  &.inverted {
    border: 1px solid colors.$color-inverted;
    color: colors.$color-inverted;

    &:hover {
      background-color: colors.$color-inverted;
    }
  }
}
