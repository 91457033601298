
































































































































































































































































































@use "~stylesheets/frontend/variables/colors";
@import "~include-media/dist/include-media";

$breakpoints: (
  "tablet": 1000px,
);

.components__score-item {
  .hueish {
    padding: 19px;
    border-bottom: 2px solid colors.$color-white;
  }

  .filter-text, .collapse-text{
    font-size: 12px;
    line-height: 12px;
  }

  .collapse-text {
    cursor: pointer;
  }

  .free-text {
    margin-top: 20px;
    margin-bottom: 20px;

    width: 100%;
    resize: none;
  }

  .bubble {
    margin-right: 16px;
    margin-left: 16px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    @include media("<tablet") {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media print {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }

  .bubble--user-input {
    margin-bottom: 20px;
  }

  .text {
    @media print {
      font-size: 14px;
    }
  }

  .more-text {
    display: none;
    padding: 24px;

    &.active {
      display: block;
    }

    .tab-content {
      font-size: 16px;
      color: colors.$color-default;

      .previous-scores {
        display: flex;
        flex-wrap: wrap;
        max-width: 820px;

        &--free-text {
          display: block;
          max-width: 100%;
          width: 100%;
        }

        .previous-score-container {
          display: flex;
          align-items: center;
          width: 200px;
          margin-top: 5px;
          margin-bottom: 5px;

          &--free-text {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: start;
          }

          .previous-score-bubble {
            margin-right: 10px;

            &--free-text {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.item--edited {
  > i { font-size: 16px; }
  margin-top: 10px;
  align-content: center;
}

.keywords {
    display: block;

    ::v-deep * {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 26px;
    }
  }
