.license_index {
  .filter-headline {
    margin-bottom: 0;
    font-size: 16px;
  }

  .a-filter-headline {
    font-size: 13px;
    margin-bottom: 0;
  }

  .filter-container {
    display: flex;
    align-items: center;

    margin-bottom: 10px;

    .filter-option {
      display: flex;
      align-items: center;

      font-size: 12px;

      margin-right: 15px;

      input {
        margin-right: 5px;

        width: 10px;
        height: 10px;
      }
    }
  }

  .filter-button {
    background-color: $color-green;
    color: $color-white;

    padding: 5px;
    font-size: 12px;
    border: none;

    cursor: pointer;
  }
}
