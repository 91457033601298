


































































































































































































































































































































































































































































































.components__cnt-license {
  @import "~stylesheets/frontend/variables/colors";

  .license__not-logged-in {
    padding: 20px;
    background-color: $color-black-very-light;
  }

  .license__option {
    display: block;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid $color-green;

    display: flex;
    align-items: center;

    input, select {
      margin-right: 20px;
    }
  }

  .license__option--selected {
    background-color: $color-green;
    color: $color-inverted;
  }

  .license__option--disabled {
    background-color: $color-black-vertical-line;
    border: 1px solid $color-black-vertical-line;
  }

  .license__input-container {
    margin-top: 40px;
  }

  .license__input-wrapper {
    margin-top: 5px;

    textarea {
      width: 100%;
    }
  }

  .license__input-container--no-top-margin {
    margin-top: 0;
  }

  .license__input-container--with-bottom-margin {
    margin-bottom: 20px;
  }

  .license__select-amount {
    select {
      padding: 1px 2px;
    }
  }

  .license__confirmation {
    margin-top: 40px;
  }

  .booking__error {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
  }

  .trainee-field {
    margin-top: 10px;

    label {
      display: inline-block;
      width: 300px;
    }

    input {
      display: inline-block;
      width: 300px;
    }
  }

  .button {
    background-color: $color-green;
    color: $color-inverted;

    padding: 10px 20px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .button--disabled {
    background-color: $color-black-inactive;
    cursor: not-allowed;
  }
}
