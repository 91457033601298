

.registration-form {
  form {
    // General grid definition
    @import "~include-media/dist/include-media";

    $breakpoints: (
      "large": 790px,
      "medium": 460px
    );

    $_grid_gutter: 10px;

    @mixin two-equal-columns($breakpoint) {
      @include media("#{$breakpoint}") {
        width: calc( (100% - #{2*$_grid_gutter}) / 2);
      }
    }

    @mixin one-big-column($breakpoint) {
      @include media("#{$breakpoint}") {
        width: calc( 100% - #{1*$_grid_gutter} );
      }
    }

    @mixin two-third-column($breakpoint, $gutters: 2) {
      @include media("#{$breakpoint}") {
        width: calc( 2 * (100% - #{$gutters*$_grid_gutter}) / 3);
      }
    }

    @mixin one-third-column($breakpoint, $gutters: 2) {
      @include media("#{$breakpoint}") {
        width: calc( (100% - #{$gutters*$_grid_gutter}) / 3);
      }
    }

    .button {
      @include one-big-column("<medium");
    }

    .form-row {
      // gutter
      > div:not(:last-child) {
        margin-right: $_grid_gutter;
      }

      // ROW 1
      $_salutation_title_width: 10%;
      .salutation, .title {
        width: $_salutation_title_width;

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }
      .first_name, .last_name {
        width: calc( (#{100% - 2*$_salutation_title_width} - #{3*$_grid_gutter})/2 );

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }

      // ROW 2
      .email, .doctors_association_value {
        width: calc( (100% - #{1*$_grid_gutter})/2);

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }

      // ROW 3
      $_address_width: 50%;
      $_zip_width: 15%;
      .address {
        width: $_address_width;

        @include one-big-column("<large");
      }
      .zip {
        width: $_zip_width;
      }
      .city {
        width: calc(#{100% - $_address_width - $_zip_width} - #{2*$_grid_gutter})
      }

      .zip, .city {
        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }

      // ROW 4
      .password, .password_confirmation {
        width: calc((100% - #{1*$_grid_gutter})/2);

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }

      // ROW 5
      &.checkboxes {
        margin-top: 15px;
        margin-bottom: 15px;

        @include media("<medium") {
          display: block;

          .form-checkbox {
            margin-bottom: 10px;
          }
        }

        .form-checkbox:first-child {
          margin-right: 30px;
        }
      }

      // Role specific rows
      .role_type {
        @include one-big-column("<large");
      }

      // WBB, ROW 1
      .lanr, .practice_training_permission_duration {
        width: calc( (100% - #{1*$_grid_gutter}) / 2 );

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }
      // WBB, ROW 2
      .practice_expertises {
        width: 100%;
      }

      // ÄiW, ROW 1
      .practice_name {
        @include two-third-column(">large", 1);
        @include one-big-column("<=large");
      }
      .practice_bsnr--short {
        @include one-third-column(">large", 1);
        @include one-big-column("<=large");

      }

      .practice_name--large {
        width: 100%;
      }

      // ÄiW, ROW 2
      $_practice_address_width: 50%;
      $_practice_zip_width: 15%;
      .practice_address {
        width: $_practice_address_width;

        @include one-big-column("<large");
      }
      .practice_zip {
        width: $_practice_zip_width;
      }
      .practice_city {
        width: calc(#{100% - $_practice_address_width - $_practice_zip_width} - #{2*$_grid_gutter})
      }

      .practice_zip, .practice_city {
        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }

      // ÄiW, ROW 3
      $_x_of_training: 40%;
      .starting_of_training, .ending_of_training {
        width: $_x_of_training;

        @include two-equal-columns("<large");
        @include one-big-column("<medium");
      }
      .training_position_amount {
        width: calc( #{100% - 2*$_x_of_training} - #{2*$_grid_gutter} );

        @include one-big-column("<large");
      }
    }
  }
}

