@font-face {
	font-family: "frontend-icons";
	font-display: block;
	src: url("/packs/webfont.frontend-icons.43121ea54f3822dc6e766b948a991dcd.eot?#iefix") format("embedded-opentype"),
url("/packs/webfont.frontend-icons.31f26a351ed43caaafdca59d04ce6686.woff") format("woff"),
url("/packs/webfont.frontend-icons.97c265b667f2925a72ba3ba5f1afb4cf.woff2") format("woff2"),
url("/packs/webfont.frontend-icons.36e0b4ac271ee76822ec4ebafd41a17e.ttf") format("truetype"),
url("/packs/webfont.frontend-icons.1fd9039e54ae949405b21b34a5f59990.svg#frontend-icons") format("svg");
}

.frontend-icon {
	line-height: 1;
}

.frontend-icon:before {
	font-family: frontend-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.fi-view_feedback:before {
	content: "\f101";
}
.fi-ic_school:before {
	content: "\f102";
}
.fi-ic_school--stroke:before {
	content: "\f103";
}
.fi-logo_paedcompenda:before {
	content: "\f104";
}
.fi-fa_bars:before {
	content: "\f105";
}
.fi-fa_times:before {
	content: "\f106";
}
.fi-fa_user:before {
	content: "\f107";
}
.fi-fa_check:before {
	content: "\f108";
}
.fi-fa_edit:before {
	content: "\f109";
}
.fi-fa_eye:before {
	content: "\f10a";
}
.fi-fa_lock-open:before {
	content: "\f10b";
}
.fi-fa_lock:before {
	content: "\f10c";
}
.fi-fa_expand:before {
	content: "\f10d";
}
.fi-fa_compress:before {
	content: "\f10e";
}
.fi-fa_stamp:before {
	content: "\f10f";
}
.fi-fa_trash:before {
	content: "\f110";
}
.fi-fa_download:before {
	content: "\f111";
}
.fi-fa_folder-tree-solid:before {
	content: "\f112";
}
.fi-fa_file-pen:before {
	content: "\f113";
}
.fi-fa_reply:before {
	content: "\f114";
}
.fi-fa_lightbulb:before {
	content: "\f115";
}
.fi-fa_newspaper:before {
	content: "\f116";
}
.fi-mail:before {
	content: "\f117";
}
.fi-info:before {
	content: "\f118";
}
.fi-assignment:before {
	content: "\f119";
}
.fi-cloud_download:before {
	content: "\f11a";
}
.fi-arrow_right_alt:before {
	content: "\f11b";
}
.fi-vpn_key:before {
	content: "\f11c";
}
.fi-school:before {
	content: "\f11d";
}
.fi-autorenew:before {
	content: "\f11e";
}
.fi-remove_red_eye:before {
	content: "\f11f";
}
.fi-add_box:before {
	content: "\f120";
}
.fi-add:before {
	content: "\f121";
}
.fi-expand_less:before {
	content: "\f122";
}
.fi-expand_more:before {
	content: "\f123";
}
.fi-close:before {
	content: "\f124";
}
.fi-check:before {
	content: "\f125";
}
.fi-search:before {
	content: "\f126";
}
.fi-printer:before {
	content: "\f127";
}
