

































.components__rec-checkboxes-selector {
  p,
  div {
    font-size: 16px;
  }

  .causes-container {
    display: flex;
    flex-flow: row wrap;
  }

  .causes-entry {
    margin-right: 15px;
    width: 350px;
    margin-bottom: 15px;

    label {
      display: flex;
    }
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
