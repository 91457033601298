// Stylings in here should style tags (without classes)

@import "./variables/font-families";
@import "./variables/colors";

* {
  font-family: $font-family_default;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  border: 1px solid $color-black-inactive;
  border-bottom: none;
}

p, ul, li {

  margin-top: 3px;
  color: $color-default;
  font-size: 18px;
  line-height: 28px;
}

h1, h2 {
  font-weight: 300;
  color: $color-green;
}

input[type="checkbox"] {
  appearance: none; // Hide "browser-checkbox"

  border: 1px solid $color-black-input-border;
  width: 15px;
  height: 15px;

  font-size:10px;

  &:checked {
    background-image: url('~images/checkbox_checked.png');
    border: 1px solid transparent;
    background-position: center;
    background-size: cover;
  }
}

textarea {
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  thead, tfoot {
    td, th {
      background-color: $color-black-placeholder;
    }

    tr {
      border: 1px solid black;
    }
  }

  thead, tbody, tfoot {
    th, td {
      padding: 15px;
      text-align: left;
      vertical-align: top;
      border-right: 1px solid black;
      border-left: 1px solid black;
    }

    tr.table-row--highlighted {
      th, td {
        background-color: $color-black-input-border;
      }
    }
  }



  tbody {
    tr:nth-child(even) {background-color: $color-black-vertical-line;}
  }
}
