














.components__rec-history {
  display: flex;
  flex-wrap: wrap;

  .history-button {
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

    font-size: 14px;
  }
}
