







.components__back-button {
  float: right;
  cursor: pointer;

  .sheet-close-button__label {
    font-size: 16px;
    vertical-align: middle;
  }
}
