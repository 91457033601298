








@import "~stylesheets/frontend/variables/routes/content-page";
@import "~stylesheets/frontend/variables/components/side-bar";

.components__side-bar-button {
  cursor: pointer;
  margin-bottom: 20px;

  &.padded {
    position: relative;
    height: 30px;

    .link {
      position: absolute;
      top: $content-page_padding-top_mobile;
      left: $content-page_padding-left_mobile;
    }
  }
}
