




















@import "~stylesheets/frontend/variables/colors";

.components__rec-select {
  .label-container {
    margin-bottom: 3px;
  }

  label {
    width: 100%;

    color: $color-default;
    font-size: 14px;
  }
}
