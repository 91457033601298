@import "~stylesheets/frontend/variables/routes/front-page";
@import "~stylesheets/frontend/variables/components/top-bar";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "phone2": 500px,
  "tablet": 600px,
  "tablet2": 720px,
  "desktop": 1078px,
  "large": 1260px,
);

$height_breakpoint: 800px;
$reduced_height_padding: 35px;

.banner {
  max-width: $front-page_max_width;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  padding-left: $front-page_padding-left;
  padding-right: $front-page_padding-right - 20px; // 20px are the padding inside the flower image png that we thus dont need

  @include media("<tablet") {
    padding-left: $front-page_padding-left_mobile;
    padding-right: $front-page_padding-right_mobile;
  }

  padding-bottom: 20px;
  @include media("<phone") {
    padding-bottom: 15vw;
  }
}

.banner_top-bar-spacing {
  padding-top: $top-bar_height;
}

.banner__horizontal {
  @include media(">=desktop") {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  @include media("<large") {
    justify-content: space-between;
  }

  @include media("<phone") {
    padding-top: 10vw;
  }
}

.banner__column--first{
  @include media(">=large") {
    margin-right: 170px;
  }
}
.banner__column--second {
  @include media("<desktop", ">=tablet") {
    text-align: right;
  }

  @include media("<tablet") {
    text-align: center;
  }
}

.banner__title {
  font-size: 55px;
  font-family: $font-family_project-title;
  font-weight: $font-weight_project-title;
  color: $color-white_project-title;
  margin-bottom: 8px;
  margin-top: 0;

  @include media("<tablet") {
    font-size: 10vw;
    margin-bottom: 5px;
  }
}

.banner__subtitle {
  font-size: 28px;
  margin-bottom: 30px;
  color: $color-white_project-title;

  @include media("<desktop") {
    margin-bottom: 20px;
  }

  @include media("<tablet") {
    font-size: 5vw;
  }
}

.banner__image {
  width: 390px;

  @include media("<desktop") {
    margin-top: 50px;
  }

  @include media("<phone") {
    width: 50%;
    box-sizing: border-box;
    margin-top: 10vw;
  }
}
