@media print {
  .print--hide {
    display: none !important;
  }

  .print--show {
    display: block !important;
  }

  .print--no-padding {
    padding: 0 !important;
  }

  .print--no-margin {
    margin: 0 !important;
  }

  .print--no-background {
    background-color: initial !important;
    background: initial !important;
  }

  .print--full-width {
    width: 100% !important;
    max-width: initial !important;
  }

  .print--no-shadow {
    box-shadow: none !important;
  }

  .print--avoid-page-break {
    page-break-inside: avoid !important;
  }

  .print--avoid-break-page-after {
    page-break-after: avoid !important;
  }

  .print--black-and-white {
    color: black;
    background-color: transparent;
  }
}
