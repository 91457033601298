.password-change-form {
  margin-left: 0;

  form {
    // General grid definition
    @import "~include-media/dist/include-media";

    $breakpoints: (
      "medium": 880px
    );

    $_grid_gutter: 10px;

    @mixin one-big-column($breakpoint) {
      @include media("#{$breakpoint}") {
        width: calc( 100% - #{1*$_grid_gutter} );
      }
    }

    .button {
      @include one-big-column("<medium");
    }

    .form-row {
      // gutter
      > div {
        margin-right: $_grid_gutter;
      }

      .email, .current_password, .password, .password_confirmation {
        width: calc( (100% - #{2*$_grid_gutter}) / 2 );

        @include one-big-column("<medium");
      }

    }
  }
}
